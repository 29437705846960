<template>
	<w-flex scroll-y>
		<ServiceDetails v-if="project && project.id && serviceId" :project="project" :value="currentService" />
		<ProjectDetails v-else-if="project && project.id" :value="project" />
	</w-flex>
</template>

<script>
import ProjectManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
export default {
	name: 'ProjectDrawer',
	components: {
		ProjectDetails: () => ({
			component: import('@/components/Offers/CustomersManager/ProjectDetails')
		}),
		ServiceDetails: () => ({
			component: import('@/components/Offers/CustomersManager/ServiceDetails')
		})
	},
	mixins: [ProjectManagerModuleGuard],
	props: {
		projectId: {
			default: undefined,
			required: false,
			type: Number
		},
		serviceId: {
			default: undefined,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			loading: false,
			project: null
		}
	},
	computed: {
		currentService: function () {
			if (this.loading || !this.project) {
				return undefined
			}

			const serviceId = this.serviceId
			return this.project.services.find(service => service.id === serviceId)
		}
	},
	watch: {
		projectId: {
			handler: function (newProjectId, oldProjectId) {
				if (newProjectId !== oldProjectId && !isNaN(newProjectId)) {
					this.findProject(newProjectId)
				} else if(isNaN(newProjectId)) {
					this.appService.goTo('customer-services')
				}
			},
			immediate: true
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ action: this.onProjectUpdated, event: this.events.PROJECT_UPDATED },
				{ action: this.onServiceDeleted, event: this.events.SERVICE_DELETED },
				{ action: this.onServiceUpdated, event: this.events.SERVICE_UPDATED }
			]
		},
		findProject: function (projectId) {
			this.loading = true
			this.$emit('loading')
			this.service
				.findProject(projectId)
				.then(project => {
					this.project = project
				})
				.finally(() => {
					this.$emit('loaded')
					this.loading = false
				})
		},
		onProjectUpdated: function (project) {
			this.updateProjectObject(project)
		},
		onServiceDeleted: function (service) {
			const serviceIndex = this.project.services.findIndex(s => s.id === service.id)

			if (serviceIndex !== -1) {
				this.project.services.splice(serviceIndex, 1)
			}

			this.service.selectProject(this.project.id)
		},
		onServiceUpdated: function (service) {
			const serviceItem = this.project.services.find(s => s.id === service.id)

			if (service) {
				serviceItem.update(service)
			}
		},
		updateProjectObject: function (newObject) {
			if (newObject.id === this.project.id) {
				this.project.update(newObject)
			}
		}
	}
}
</script>
